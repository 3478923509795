import { OrInputV3 as OrInput, OrCheckboxV3 as OrCheckbox, OrButtonV3 as OrButton, OrModalV3 as OrModal, OrNotificationV3 as OrNotification, OrIconButtonV3 as OrIconButton, useResponsive, } from '@onereach/ui-components';
import _ from 'lodash';
import { storeToRefs } from 'pinia';
import { load as loadRecaptcha, getInstance as getCaptchaInstance } from 'recaptcha-v3';
import { ref, computed, defineComponent, watch, nextTick, reactive, onBeforeMount, onBeforeUnmount, } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import authService from '@/api/auth';
import GoogleLogin from '@/components/Auth/GoogleLogin.vue';
import PasswordRecovery from '@/components/Auth/PasswordRecovery.vue';
import OrAnimationLogo from '@/components/OrAnimationLogo/OrAnimationLogo.vue';
import OrSubtitles from '@/components/OrLoginSubtitles/OrSubtitles.vue';
import OrLogo from '@/components/OrLogo';
import { useAuth } from '@/composables/useAuth';
import useI18n from '@/composables/useI18n';
import config from '@/config';
import LoginContainer from '@/layouts/LoginContainer.vue';
import { useAuthStore } from '@/stores/auth/auth';
import { checkRwcInterval } from '@/utils/constans';
import { getFormatedError, ErrorActions } from '@/utils/loginPage';
export default defineComponent({
    components: {
        LoginContainer,
        GoogleLogin,
        OrLogo,
        OrInput,
        OrCheckbox,
        OrButton,
        OrIconButton,
        OrModal,
        OrNotification,
        OrAnimationLogo,
        OrSubtitles,
        PasswordRecovery,
    },
    props: {
        shouldRedirectToDefaultProvider: {
            type: Boolean,
            default: false,
        },
        showGoogleSSo: {
            type: Boolean,
            default: false,
        },
    },
    setup(props) {
        const route = useRoute();
        const router = useRouter();
        const authStore = useAuthStore();
        const { translate } = useI18n();
        const { getAuthProviders, initiateExternalLogin, authorizeRedirect, redirectToDefaultProviderIfExists, } = useAuth();
        //computed properties
        const expiredUsername = computed(() => { var _a; return (_a = authStore.multiUser) === null || _a === void 0 ? void 0 : _a.username; });
        const { authorized, isSSO, redirectPath, error: authError, initialPath, allowGuest, twoFactorCheck, sso_needs_two_factor, } = storeToRefs(authStore);
        const GOOGLE_LOGIN = computed(() => config.GOOGLE_LOGIN || props.showGoogleSSo);
        const containerVariant = computed(() => twoFactorCheck.value ? 'corners' : 'default');
        const storageKeepLoggedIn = computed(() => (sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.getItem('keepLoggedIn')) === 'true');
        //mapped authStore actions
        const { signIn, signOut, cancelTwoFactorCheck, authorizeMult, validateMultiUser, sucessAuth, setSSOTwoFactor, setIsSSO, ssoLoginMFA, } = authStore;
        if (props.shouldRedirectToDefaultProvider) {
            redirectToDefaultProviderIfExists(redirectPath.value);
        }
        //reactive data
        const credentials = reactive({
            username: '',
            password: '',
        });
        const emailError = ref('');
        const passwordError = ref('');
        const serviceError = ref('');
        const keepLoggedIn = ref(false);
        const verificationCode = ref('');
        const authenticating = ref(false);
        const accountData = ref(_.pick(route.query, ['accountName', 'accountId']));
        const externalProviders = ref(getAuthProviders());
        const isOpenRecovery2fa = ref(false);
        const recoveryCode = ref('');
        const recoveryError = ref('');
        const showCaptchaV2Checkbox = ref(false);
        const googleSSOLoading = ref(false);
        const isExpired = ref(false);
        const { isMobile, isDesktop } = useResponsive();
        const captchaWidgetId = ref(null);
        // template refs
        const twoFactorInput = ref(null);
        const resetPasswordModal = ref(null);
        const captchaV2Checkbox = ref(null);
        //methods
        const logout = async () => {
            authStore.cancelTwoFactorCheck();
            router.push('/logout');
        };
        const submit = async (captchaV2CheckboxToken) => {
            if (sso_needs_two_factor.value) {
                authenticating.value = true;
                await ssoLoginMFA(isOpenRecovery2fa.value ? recoveryCode.value : verificationCode.value);
                authenticating.value = false;
                if (isOpenRecovery2fa.value && serviceError.value) {
                    recoveryError.value = serviceError.value;
                }
                return;
            }
            // removing error from form
            emailError.value = '';
            passwordError.value = '';
            serviceError.value = '';
            recoveryError.value = '';
            if (credentials.username && credentials.password) {
                authenticating.value = true;
                const recaptcha = await loadRecaptcha(config.GOOGLE_CAPTCHA_SITE_KEY_V3);
                let captchaToken;
                if (_.isString(captchaV2CheckboxToken)) {
                    captchaToken = captchaV2CheckboxToken;
                }
                else {
                    captchaToken = await recaptcha.execute('signin');
                }
                if (twoFactorCheck.value) {
                    await signIn({
                        credentials: credentials,
                        keepLoggedIn: keepLoggedIn.value,
                        verificationCode: isOpenRecovery2fa.value ? recoveryCode.value : verificationCode.value,
                        captchaToken,
                        isCaptchaV2Checkbox: _.isString(captchaV2CheckboxToken),
                        accountData: accountData.value,
                    });
                    if (serviceError.value && isOpenRecovery2fa.value) {
                        recoveryError.value = serviceError.value;
                        authenticating.value = false;
                    }
                    return;
                }
                else {
                    await signIn({
                        credentials: credentials,
                        keepLoggedIn: keepLoggedIn.value,
                        captchaToken,
                        isCaptchaV2Checkbox: _.isString(captchaV2CheckboxToken),
                        accountData: accountData.value,
                    });
                    return;
                }
            }
            else if (!credentials.username) {
                emailError.value = 'This field is required.';
            }
            else if (!credentials.password) {
                passwordError.value = 'This field is required.';
            }
            authenticating.value = false;
        };
        const closeExpiredNotification = () => {
            isExpired.value = false;
        };
        const showForgotPopup = () => {
            var _a;
            (_a = resetPasswordModal.value) === null || _a === void 0 ? void 0 : _a.open();
        };
        const toogleOpenRecovery2fa = (bool) => {
            isOpenRecovery2fa.value = bool;
            if (!bool) {
                recoveryCode.value = '';
                recoveryError.value = '';
            }
        };
        const renderCapthcaV2Checkbox = async () => {
            showCaptchaV2Checkbox.value = true;
            // eslint-disable-next-line no-undef
            if (grecaptcha && !captchaWidgetId.value) {
                const captchaToken = await new Promise(resolve => {
                    // eslint-disable-next-line no-undef
                    captchaWidgetId.value = grecaptcha.render(captchaV2Checkbox.value, {
                        sitekey: config.GOOGLE_CAPTCHA_SITE_KEY_V2_CHECKBOX,
                        // @ts-ignore
                        callback: resolve,
                    });
                });
                await submit(captchaToken);
                showCaptchaV2Checkbox.value = false;
            }
        };
        const listenIframe = async ({ data }) => {
            if (data.type === 'SSO_SUCCESS_LOGIN') {
                if (data.user.tokenType === 'multi-user') {
                    googleSSOLoading.value = true;
                    await authService.saveMultiUser(data.user, keepLoggedIn.value);
                    sessionStorage === null || sessionStorage === void 0 ? void 0 : sessionStorage.setItem('keepLoggedIn', `${keepLoggedIn.value}`);
                    try {
                        await validateMultiUser();
                        await authorizeMult({
                            keepLoggedIn: keepLoggedIn.value,
                            accountData: accountData.value,
                        });
                    }
                    catch (e) {
                        googleSSOLoading.value = false;
                    }
                }
                else {
                    authService.saveUser({
                        user: data.user,
                        long: keepLoggedIn.value,
                    });
                    sucessAuth(data.user);
                }
                cancelTwoFactorCheck();
            }
            if (data.type === 'SSO_TWO_FACTOR_CHECK') {
                setSSOTwoFactor(data);
            }
        };
        const pasteFromBuffer = async () => {
            try {
                if (!navigator.clipboard) {
                    throw new Error('Clipboard API not available');
                }
                const text = await navigator.clipboard.readText();
                verificationCode.value = text;
            }
            catch (e) {
                console.error('Failed to read clipboard contents: ', e);
                if (e instanceof Error) {
                    serviceError.value = e.message;
                }
            }
        };
        //watchers
        watch(authorized, (value) => {
            if (value) {
                authorizeRedirect('user-action').catch(() => authenticating.value = false);
            }
        });
        watch(authError, (value) => {
            if (value && (typeof value === 'string' || value instanceof Error)) {
                const _error = getFormatedError(value, translate);
                serviceError.value = _error.message;
                if (_error.action === ErrorActions.Expired) {
                    const currentUrl = window.location.origin;
                    const redirectUrl = `${currentUrl}?expired`;
                    window.location.href = redirectUrl;
                    return;
                }
                else if (_error.action === ErrorActions.RenderCaptcha) {
                    renderCapthcaV2Checkbox();
                }
                authenticating.value = false;
            }
        });
        watch(twoFactorCheck, (value) => {
            if (value) {
                authenticating.value = false;
                serviceError.value = '';
                nextTick(() => {
                    var _a;
                    (_a = twoFactorInput.value) === null || _a === void 0 ? void 0 : _a.focus();
                });
            }
        });
        watch(serviceError, (value) => {
            if (isOpenRecovery2fa.value) {
                recoveryError.value = value;
            }
        });
        watch(verificationCode, (value) => {
            if (serviceError.value) {
                serviceError.value = '';
            }
            if (value.length === 6) {
                submit();
            }
        });
        onBeforeMount(async () => {
            if (route.query.sso) {
                setIsSSO();
            }
            window.addEventListener('message', listenIframe);
            if (authorized.value) {
                // validating user token each time in case token is expired or not valid
                return authService.validateUser(allowGuest.value)
                    .then(async () => {
                    if (isSSO.value && accountData.value) {
                        return authorizeMult({
                            keepLoggedIn: storageKeepLoggedIn.value,
                            accountData: accountData.value,
                            source: 'cookie',
                        });
                    }
                    authorizeRedirect('cookie');
                })
                    .catch(signOut);
            }
            if (config.HELPER_BOT_ENABLED) {
                // Hide chat on login page.
                const interval = setInterval(() => {
                    const chat = document.querySelector('.or-rwc');
                    if (chat) {
                        chat.style.display = 'none';
                        clearInterval(interval);
                    }
                }, checkRwcInterval);
            }
            await loadRecaptcha(config.GOOGLE_CAPTCHA_SITE_KEY_V3);
            const isExpiredQuery = route.query.expired === null;
            if (isExpiredQuery) {
                if (expiredUsername.value) {
                    credentials.username = expiredUsername.value;
                    router.replace({ query: {} });
                    isExpired.value = isExpiredQuery;
                }
                else {
                    isExpired.value = true;
                    router.replace({ query: {} });
                }
            }
        });
        onBeforeUnmount(() => {
            var _a;
            if (config.HELPER_BOT_ENABLED) {
                // Show chat on pages except login
                const chat = document.querySelector('.or-rwc');
                if (chat) {
                    chat.style.display = 'block';
                }
            }
            (_a = getCaptchaInstance()) === null || _a === void 0 ? void 0 : _a.hideBadge();
            window.removeEventListener('message', listenIframe);
        });
        return {
            isMobile,
            isDesktop,
            captchaWidgetId,
            credentials,
            getAuthProviders,
            initiateExternalLogin,
            authorizeRedirect,
            emailError,
            redirectToDefaultProviderIfExists,
            authorized,
            redirectPath,
            authError,
            initialPath,
            allowGuest,
            twoFactorCheck,
            sso_needs_two_factor,
            expiredUsername,
            twoFactorInput,
            resetPasswordModal,
            captchaV2Checkbox,
            GOOGLE_LOGIN,
            containerVariant,
            externalProviders,
            logout,
            submit,
            closeExpiredNotification,
            showForgotPopup,
            toogleOpenRecovery2fa,
            renderCapthcaV2Checkbox,
            translate,
            keepLoggedIn,
            authenticating,
            recoveryCode,
            recoveryError,
            isOpenRecovery2fa,
            googleSSOLoading,
            showCaptchaV2Checkbox,
            passwordError,
            serviceError,
            verificationCode,
            isExpired,
            pasteFromBuffer,
        };
    },
});
