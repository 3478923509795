import { createPinia as createPiniaOrigin } from 'pinia';
import { PiniaLogger } from 'pinia-logger';
import config from '@/config';
import SentryMonitoringPlugin from './plugins/sentry-monitoring-plugin/SentryMonitoringPlugin';
const storeLogs = config.STORE_LOGS;
const local = config.LOCAL;
function createPinia() {
    return createPiniaOrigin()
        .use(PiniaLogger({
        expanded: false,
        disabled: storeLogs || local,
    }))
        .use(SentryMonitoringPlugin({
        disabled: !storeLogs || local,
    }));
}
export default createPinia;
